import { getDateDifferenceInDays } from "@goodcollect/shared/dates";
import { useSearchParams } from "@remix-run/react";
import { isDate, set } from "@goodcollect/shared/dates";
import React, { useMemo } from "react";
import { addBusinessDays, parseStringToDate } from "utils/dateformat.ts";

export const useDateRanges = ({
  defaultEndDate = null,
  defaultStartDate = null,
  defaultAdditionalDate = null,
  minDate = null,
}: {
  defaultStartDate?: string | null;
  defaultEndDate?: string | null;
  defaultAdditionalDate?: string | null;
  minDate?: string | null;
}) => {
  const [searchParams] = useSearchParams();

  const startDateParam = searchParams.get("startDate");
  const endDateParam = searchParams.get("endDate");

  const minimumDate = React.useMemo(() => {
    const SATURDAY = 6;
    const SUNDAY = 0;
    const isDateOnWeekend = (date: Date) => {
      return date.getDay() === SUNDAY || date.getDay() === SATURDAY;
    };
    const currentDate = set(new Date(), {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
    });
    const defaultMinDate = addBusinessDays({
      date: currentDate,
      daysToAdd:
        currentDate.getHours() < 17 || isDateOnWeekend(currentDate) ? 2 : 3,
    });
    if (minDate) {
      const date = parseStringToDate(minDate);
      if (isDate(date)) {
        return date;
      }
    }
    return defaultMinDate;
  }, [minDate]);

  const [selectedDates, setSelectedDates] = React.useState<{
    startDate: string;
    endDate: string;
    additionalDate?: string;
  }>(() => {
    return {
      startDate: defaultStartDate ?? startDateParam ?? "",
      endDate: defaultEndDate ?? endDateParam ?? "",
      additionalDate: defaultAdditionalDate ?? "",
    };
  });

  const isOutated = React.useMemo(() => {
    if (!selectedDates.startDate) return false;
    const startDate = parseStringToDate(selectedDates.startDate);

    return startDate < minimumDate;
  }, [selectedDates?.startDate, minimumDate]);

  const dateDifferenceInDays = useMemo(() => {
    if (!selectedDates.startDate || !selectedDates.endDate) return 0;
    return getDateDifferenceInDays({
      endDate: parseStringToDate(selectedDates.endDate),
      startDate: parseStringToDate(selectedDates.startDate),
    });
  }, [selectedDates.endDate, selectedDates.startDate]);

  return {
    selectedDates,
    setSelectedDates,
    minimumDate,
    isOutated,
    dateDifferenceInDays,
  };
};
